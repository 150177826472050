import { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useSessionData, useBooleanFeatureFlag } from '@confluence/session-data';

import { UserPersonalizationQuery } from '../useOnboardingEligible/UserPersonalizationQuery.experimentalgraphql';
import { CONTEXTUAL_INVITE_NUDGE } from '../../constants/onboarding-state/onboarding-contextual-invite-nudge';
import { EDITOR_ONBOARDING_STATE_KEYS } from '../../constants/onboarding-state/onboarding-editor-constants';
import {
	useGetOnboardingState,
	useOnboardingState,
	deserializeState,
} from '../onboardingState/onboardingState';
import { useIsXflowUser } from '../useIsXflowUser';
import { memoizedGetInvitePermissions } from '../../utils/getInvitePermissions';
import { COHORT_KEY, Cohorts } from '../../constants/cohorts';

const CONFLUENCE_KEY = 'confluence';

const CONTEXTUAL_INVITE_NUDGE_XFLOW_EXPERIMENT = 'cc_onboarding_contextual_invite_nudge_xflow';
const LIVE_PAGE_NUDGE = {
	LIVE_PAGE_CONTEXTUAL_NUDGE_SEEN: 'live-page-nudge:wasLivePageNudgeSeen',
};

/**
 * Determines eligibility for the Contextual Invite Nudge experiment
 * @returns {object} - an object containing:
 * @returns {boolean} contextualInviteNudgeEligibleTag- Determines whether the user is eligible for the contextual invite nudge
 * @returns {object} onboardingState - Relevant onboarding state data
 */
export const useContextualInviteNudge = (): {
	contextualInviteNudgeEligibleTag: boolean;
	onboardingState: any;
} => {
	const [isEligibleTag, setIsEligibleTag] = useState(false);

	// Contexts and hooks
	const { isLicensed, isLoggedIn, userId, cloudId } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { setOnboardingState } = useOnboardingState();
	const isOnboardingFlagEnabled = useBooleanFeatureFlag(
		'confluence.frontend.onboarding.experience',
	);

	// Experiments
	const contextualInviteNudgeXflowCohort = FeatureGates.getExperimentValue<Cohorts>(
		CONTEXTUAL_INVITE_NUDGE_XFLOW_EXPERIMENT,
		COHORT_KEY,
		Cohorts.NOT_ENROLLED,
		{ fireExperimentExposure: false },
	);

	// Onboarding state
	const {
		data: onboardingStateData,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		[
			CONTEXTUAL_INVITE_NUDGE.EXPERIMENT_ELIGIBLE_TAG,
			CONTEXTUAL_INVITE_NUDGE.WAS_CONFETTI_SEEN,
			EDITOR_ONBOARDING_STATE_KEYS.HAS_USER_PUBLISHED,
			LIVE_PAGE_NUDGE.LIVE_PAGE_CONTEXTUAL_NUDGE_SEEN,
		],
		!isOnboardingFlagEnabled,
	);

	const onboardingState = deserializeState(onboardingStateData);
	const { contextualInviteNudgeEligibleTag, hasUserPublished } = onboardingState;

	// Derived variables
	const isLicensedAndLoggedIn = isLoggedIn && isLicensed;
	const skip =
		!isOnboardingFlagEnabled ||
		!isLicensedAndLoggedIn ||
		onboardingStateLoading ||
		Boolean(contextualInviteNudgeEligibleTag) ||
		Boolean(hasUserPublished);

	// User personalization query for isEstablishedUser
	const {
		data: userPersonalizationQueryData,
		loading: userPersonalizationQueryLoading,
		error: userPersonalizationQueryError,
	} = useQuery(UserPersonalizationQuery, {
		variables: {
			userId: userId!,
		},
		skip,
	});

	const attributes: Record<string, string>[] =
		userPersonalizationQueryData?.experimentalUserPersonalization?.attributes ?? [];
	const isEstablishedUser =
		attributes.find(
			(attr: Record<string, string>) => attr?.name === 'confluence_is_established_user',
		)?.value === 'true';

	// Xflow user check
	const {
		isXflowUser,
		isLoading: xFlowLoading,
		error: xFlowError,
	} = useIsXflowUser({
		skip,
	});

	// Combined loading and error states
	const loading = userPersonalizationQueryLoading || onboardingStateLoading || xFlowLoading;
	const error = userPersonalizationQueryError || onboardingStateError || xFlowError;
	useEffect(() => {
		if (
			skip ||
			loading ||
			error ||
			isEstablishedUser ||
			(isXflowUser && contextualInviteNudgeXflowCohort === Cohorts.NOT_ENROLLED)
		) {
			return;
		}

		const fetchInvitePermsAndSetEligibility = async () => {
			const response = await memoizedGetInvitePermissions(cloudId);
			if (response instanceof Error) {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						source: 'editorOrViewPage',
						action: 'failed',
						actionSubject: 'getInvitePermissions',
						attributes: {
							message: response?.message,
							isXflowUser,
						},
					},
				}).fire();
			}

			const canInviteToConfluence: boolean = [
				...(response?.directInvite ?? []),
				...(response?.invitePendingApproval ?? []),
			].some((item) => item.includes(CONFLUENCE_KEY));

			if (canInviteToConfluence) {
				if (isXflowUser) {
					FeatureGates.manuallyLogExperimentExposure(CONTEXTUAL_INVITE_NUDGE_XFLOW_EXPERIMENT);
					if (contextualInviteNudgeXflowCohort !== Cohorts.EXPERIMENT) {
						return;
					}
				}

				void setOnboardingState({
					key: CONTEXTUAL_INVITE_NUDGE.EXPERIMENT_ELIGIBLE_TAG,
					value: 'true',
				});
				setIsEligibleTag(true);
			}
		};
		void fetchInvitePermsAndSetEligibility();
	}, [
		error,
		loading,
		skip,
		cloudId,
		contextualInviteNudgeEligibleTag,
		isEstablishedUser,
		isXflowUser,
		setOnboardingState,
		createAnalyticsEvent,
		contextualInviteNudgeXflowCohort,
	]);

	return {
		contextualInviteNudgeEligibleTag: Boolean(isEligibleTag || contextualInviteNudgeEligibleTag),
		onboardingState,
	};
};
