import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { fg } from '@confluence/feature-gating';
import { isErrorMarkedAsHandled, isGraphQLError, markErrorAsHandled } from '@confluence/graphql';
import { ConfluenceEdition, useSessionData } from '@confluence/session-data';

import { getTrialDaysLeft } from '../getTrialDaysLeft';
import { useReverseTrialEligible } from '../useReverseTrialEligible';
import { ReverseTrialCohort } from '../__types__/InstanceIsInReverseTrialQuery';

import { PremiumTipTrialDetailsQuery } from './PremiumTipTrialDetailsQuery.graphql';

const FEATURE_FLAG = 'bite_sized_feature_tasting_premium_messages';

enum COHORTS {
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	VARIATION = 'experiment',
}

const handleError = (error: ApolloError | null | undefined) => {
	if (error && (!isErrorMarkedAsHandled(error) || isGraphQLError(error))) {
		markErrorAsHandled(error);
	}
};

export const useEligibilityBiteSizedPremiumMessages = () => {
	const { edition, isLoggedIn, locale } = useSessionData();

	const isReverseTrialQueryEligible = edition === ConfluenceEdition.PREMIUM && isLoggedIn;
	const {
		error: reverseTrialError,
		reverseTrialCohort,
		loading: reverseTrialLoading,
	} = useReverseTrialEligible({ skip: !isReverseTrialQueryEligible });

	handleError(reverseTrialError);

	const isTrialDetailsQueryNotEligible =
		reverseTrialLoading ||
		Boolean(reverseTrialError) ||
		[ReverseTrialCohort.ENROLLED, undefined, null].includes(reverseTrialCohort);

	const {
		data: trialData,
		loading: trialDataLoading,
		error: trialDetailsError,
	} = useQuery(PremiumTipTrialDetailsQuery, { skip: isTrialDetailsQueryNotEligible });

	handleError(trialDetailsError);

	const trialDaysLeft = getTrialDaysLeft(trialData?.license?.trialEndDate);

	const isFeatureEligibleToDisplay =
		trialDaysLeft > 0 &&
		trialDaysLeft < 28 &&
		isReverseTrialQueryEligible &&
		!isTrialDetailsQueryNotEligible &&
		!trialDataLoading &&
		!trialDetailsError &&
		FeatureGates.getExperimentValue<COHORTS>(FEATURE_FLAG, 'cohort', COHORTS.NOT_ENROLLED) ===
			COHORTS.VARIATION;
	const isPhase2Enabled =
		isFeatureEligibleToDisplay && locale?.includes('en') && fg('cc_editions_bite_sized_phase2');
	return {
		isFeatureFlagEnabled: isFeatureEligibleToDisplay,
		isPhase2Enabled,
		daysLeft: trialDaysLeft,
	};
};
